<script setup lang="ts">
import { omit } from 'lodash'

const route = useRoute()
const openFeature = useOpenFeature()
const { studios, loaded } = storeToRefs(useFestivalManager())
const { festivalSubmissions } = storeToRefs(useFiveFestSubmission())
const { isAuthenticated, user, roles, impersonating } = storeToRefs(
  useSession()
)

const props = defineProps<{
  modelValue: boolean
}>()
const emit = defineEmits<{
  (e: 'update:modelValue', value: boolean): void
}>()
const live = computed(() => openFeature.getObject('live'))

const links = computed(() => {
  const l = []
  l.push({
    title: 'Home',
    to: '/',
    prependIcon: 'mdi-home-outline',
  })

  // l.push({
  //   title: 'Save the Dates',
  //   to: '/save-the-dates',
  //   prependIcon: 'mdi-calendar',
  // })

  if (live.value.name) {
    l.push({
      title: live.value.live ? 'LIVE NOW' : live.value.name,
      to: '/live',
      subtitle: live.value.live ? live.value.name : live.value.starts,
      prependIcon: 'mdi-radiobox-marked',
      color: 'red',
      disabled: !live.value.live,
      active: live.value.live,
    })
  }

  if (openFeature.getBool('box_office')) {
    l.push({
      title: 'Box Office',
      to: '/box-office',
      color: 'primary',
      prependIcon: 'mdi-ticket'
    })
  }

  if (openFeature.getString('inspiration_package') != '') {
    l.push({
      title: 'Inspiration Package',
      to: '/film-challenge/inspiration',
      color: 'success',
      prependIcon: 'mdi-lightbulb-on',
    })
  }

  if (
    roles.value.includes('Exit Interviewer') &&
    openFeature.getBool('exit_interviews_open')
  ) {
    l.push({
      title: 'Exit Interviews',
      to: '/admin/exit-interviews',
      prependIcon: 'mdi-video-account',
    })
  }

  if (openFeature.getBool('selection_committee')) {
    l.push({
      title: 'Selection Committee',
      to: '/selection',
      prependIcon: 'mdi-vote',
    })
  }

  if (openFeature.getBool('prize_sponsor')) {
    l.push({
      title: 'Prize Sponsor',
      to: '/prize-sponsor',
      prependIcon: 'mdi-vote'
    })
  }

  if (openFeature.getBool('online_screening')) {
    l.push({
      title: 'Online Screening',
      prependIcon: 'mdi-popcorn',
      children: [{
        title: 'Film Challenge',
        to: '/online-screening'
      }, {
        title: 'FiveFest',
        to: '/online-screening/five-fest',
      }]
    })
  }

  if (openFeature.getBool('international_judging')) {
    l.push({
      title: 'Judge International',
      to: '/judge',
      prependIcon: 'mdi-gavel',
    })
  }

  if (openFeature.getBool('festival_manager')) {
    const c = []

    c.push({
      title: 'Get Started',
      to: '/film-challenge',
      color: 'info',
      prependIcon: 'mdi-play-circle-outline',
    })

    if (openFeature.getBool('matchup_open')) {
      c.push({
        title: 'The Matchup',
        subtitle: 'Find a team to join',
        to: '/film-challenge/the-matchup',
        color: 'secondary',
        prependIcon: 'mdi-heart-search',
      })
    }

    c.push({ type: 'divider', class: 'ms-10' })

    if (!loaded.value) {
      c.push({ type: 'divider', class: 'ms-10', skelly: true })
    }

    for (const studio of studios.value || []) {
      const t = []

      t.push({
        title: 'Studio History',
        to: `/film-challenge/studios/${studio.id}`,
        color: 'info',
        prependIcon: 'mdi-history',
      })

      if (
        studio.owner_id == user.value?.id &&
        openFeature.getBool('registration_open')
      ) {
        t.push({
          title: 'Create a Team',
          to: `/film-challenge/studios/${studio.id}/teams/create`,
          color: 'info',
          prependIcon: 'mdi-account-multiple-plus',
        })
      }

      if (studio.teams.length) t.push({ type: 'divider', class: 'ms-16' })

      for (const team of studio.teams) {
        t.push({
          title: team.name,
          to: `/film-challenge/studios/${studio.id}/teams/${team.id}`,
          prependIcon: 'mdi-account-group',
        })
      }

      c.push({
        title: studio.name,
        prependIcon: 'mdi-movie-open-play-outline',
        color: 'info',
        children: t,
      })
    }

    l.push({
      title: 'Film Challenge',
      color: 'info',
      prependIcon: 'mdi-movie-open-outline',
      children: c,
    })
  }

  if (openFeature.getBool('five_fest')) {
    const c = []

    c.push({
      title: 'Submit Film',
      to: '/fivefest/films/create',
      color: 'warning',
      prependIcon: 'mdi-plus',
    })

    c.push({ type: 'divider', class: 'ms-10' })

    if (!loaded.value) {
      c.push({ type: 'divider', skelly: true })
    }

    festivalSubmissions.value?.forEach(submission => {
      c.push({
        title: submission.film_title,
        to: `/fivefest/films/${submission.id}`,
        color: 'warning',
        prependIcon: 'mdi-movie-open-play-outline',
        appendIcon: submission.submitted_at ? 'mdi-check' : 'mdi-alert',
        appendIconColor: submission.submitted_at ? 'success' : 'warning',
        appendIconTooltip: submission.submitted_at
          ? 'Submitted'
          : 'Not Submitted',
      })
    })

    l.push({
      title: 'FiveFest',
      color: 'warning',
      prependIcon: 'mdi-movie-roll',
      children: c,
    })
  }

  if (openFeature.getBool('five_fest_academy')) {
    l.push({
      title: 'FiveFest Academy',
      to: '/fivefest/academy',
      color: 'success',
      prependIcon: 'mdi-vote-outline',
    })
  }

  l.push({ type: 'divider' })

  l.push({
    title: 'Top 10',
    to: '/archive/2024/top-10',
    prependIcon: 'mdi-trophy',
  })

  l.push({
    title: 'Archive',
    to: '/archive',
    prependIcon: 'mdi-archive',
  })

  l.push({ type: 'divider' })

  l.push({
    title: 'SATO48.com',
    href: 'https://sato48.com',
    target: '_blank',
    prependIcon: 'mdi-earth',
    appendIcon: 'mdi-open-in-new',
    appendIconSize: 'small',
  })

  l.push({
    title: 'Helpdesk',
    href: 'https://help.sato48.com',
    target: '_blank',
    prependIcon: 'mdi-help-circle-outline',
    appendIcon: 'mdi-open-in-new',
    appendIconSize: 'small',
  })

  l.push({
    title: 'Community',
    href: 'https://join.slack.com/t/sato48/shared_invite/zt-opau8j4k-SvNl8K4y_eeuBtaZlRIheA',
    target: '_blank',
    prependIcon: 'mdi-slack',
    appendIcon: 'mdi-open-in-new',
    appendIconSize: 'small',
  })

  if (roles.value.includes('Super User')) {
    l.push({ type: 'divider' })
    l.push({
      title: 'Admin',
      prependIcon: 'mdi-shield-crown-outline',
      children: [
        {
          title: 'Users',
          to: '/admin/users',
        },
        {
          title: 'Teams',
          to: '/admin/teams',
        },
        {
          title: 'Film Groups',
          children: [
            {
              title: 'Films',
              to: '/admin/film-groups/films',
            },
            {
              title: 'Users',
              to: '/admin/film-groups/users'
            }
          ]
        },
        {
          title: 'Results',
          children: [
            {
              title: 'FiveFest Academy',
              to: '/admin/results/academy',
            },
            {
              title: 'Selection Committee',
              to: '/admin/results/selection',
            },
            {
              title: 'International Jury',
              to: '/admin/results/judge',
            },
          ]
        },
        {
          title: 'Audience Favorite',
          to: '/admin/audience-favorite'
        },
        {
          title: 'AC Checkin',
          to: '/admin/awards-ceremony/checkin'
        }
      ],
    })
  }

  return l
})
</script>

<template lang="pug">
v-navigation-drawer(app left :model-value="props.modelValue" @input="emit('update:modelValue', $event)")
  v-list(color="primary" density="compact" :lines="false" nav slim v-if="openFeature.getNumber('countdown_timer') > 0")
    CountdownTimer
  v-list(color="primary" density="compact" :lines="false" :items="links" nav slim item-props)
    template(v-slot:divider="{props}")
      v-skeleton-loader(v-if="props.skelly" v-bind="props" type="text, text")
      v-divider.my-2(v-else v-bind="props")
    template(v-slot:append="{isActive, item}")
      v-icon(v-if="item.children" :icon="isActive ? 'mdi-chevron-up' : 'mdi-chevron-down'")
      v-tooltip(v-else-if="item.appendIconTooltip" location="end" :text="item.appendIconTooltip")
        template(v-slot:activator="{props}")
          v-icon(v-bind="props" :icon="item.appendIcon" :color="item.appendIconColor" :size="item.appendIconSize")
      v-icon(v-else-if="item.appendIcon" :icon="item.appendIcon" :color="item.appendIconColor" :size="item.appendIconSize")
</template>
